@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .all-\[unset\] {
    all: unset;
  }
  .buy-rubies {
    @apply w-[100%] h-[30px] font-[400] text-[14px] leading-[15px] bg-red text-white flex flex-col justify-center text-center
  }
  .shop-container {
    @apply h-[54px] bg-linear-gradient flex flex-row justify-between items-center w-full
  }
  .shop-content {
    @apply text-white font-[400] text-[20px] leading-[15px] text-center
  }
  .close-icon {
    @apply h-[30px] w-[30px] text-white mr-[7px]
  }
  .user-info-container {
    @apply h-[56px] w-[100%] bg-mainB flex flex-row justify-between items-center
  }
  .rubies-coins-status {
    @apply bg-vopletOpacity flex flex-row justify-center items-center bg-vopletOpacity
  }
  .rubies-coins-status-p {
    @apply text-[14px] text-white font-[700] ml-[2px]
  }
  .buy-container {
    @apply w-[100%] bg-white flex overflow-auto
  }
  .buy-container-content {
   @apply flex flex-col justify-center items-center gap-1 min-w-[33.3333%]
  }
  .buy-rubies-btn {
    @apply w-[90px] h-[40px] bg-red border-none text-main font-[400]
  }
  .buy-coins-btn {
    @apply w-[90px] h-[40px] bg-gold border-none text-main font-[400]
  }
  .coins-for-rubies {
    @apply text-main font-[400] text-[16px] flex flex-row justify-center items-center gap-[2px] mr-[21px]
  }
  .membership {
    @apply w-[100%] h-[30px] font-[400] text-[14px] leading-[15px] bg-violet text-white flex flex-col justify-center text-center
  }
  .vip-pass-btn {
    @apply w-[144px] h-[40px] border-none text-main font-[600] text-[16px]
  }
  .pass-content {
    @apply font-[400] text-[17px] leading-[15px] text-white text-center
  }
  .pass-price-p {
    @apply font-[400] text-[16px] text-main leading-[24px]
  }
  .pass-container {
    @apply flex flex-col justify-center items-center gap-2
  }
  .pass-container-content {
    @apply w-[126px] h-[75px] rounded-sm bg-vip-gradient flex flex-col items-center justify-center
  }
  .drop-down-font {
    @apply text-main text-[14px] font-[400] leading-[15px] tracking-[0.1em]
  }
}

@keyframes riseAndSpin {
  0% {
    transform: scale(1) translateY(0) rotateY(0deg) rotateX(0deg);
  }
  30% {
    transform: scale(2) translateY(-15px) rotateY(0deg) rotateX(0deg);
  }
  100% {
    transform: scale(1) translateY(0) rotateY(720deg) rotateX(0deg);
  }
}


.rise-and-spin {
  animation: riseAndSpin 3s ease-in-out;
}

.amount-increse-animation {
  font-weight: 700;
  color: #ffc300;
}

.amount-decrese-animation {
  font-weight: 700;
  color: red;
}

@keyframes fadeAnimation {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
}

:root {
  --bg: rgba(255, 0, 0, 1);
  --c8-plum: rgba(24, 6, 47, 1);
  --collection-1-c8-green: rgba(25, 105, 106, 1);
  --collection-1-c8-lime: rgba(210, 255, 0, 1);
  --collection-1-white: rgba(255, 255, 255, 1);
}

html {
  overflow-x: hidden;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.menuLink {
  text-decoration: none;
  color:#fff;
}
.menuLink:hover {
  color: #c2e809;
}
.menuLink-dark {
  text-decoration: none;
  color: #1b062f;
}

.c8Page {
  background-color: #1b062f;
  background:
    radial-gradient(circle at top right, #1B062F 0%, rgba(123, 31, 162, 0) 70%),
    radial-gradient(circle at bottom left, #40196e 0%, rgba(123, 31, 162, 0) 70%),
    #1b062f;
  }

.footerlink {
  text-transform: none;
  color:#fff;
  text-decoration: none;
}
.footerlink:hover {
  text-decoration: underline;
  color: #c2e809;
}
.content-t {
  margin-bottom:80px;
}

#content, #sitefooter, #headerMatch {
  position: relative;
  top:0px;
  /*background: #1b062f;*/
  color: #fff;
  font-family:'Anton',Helvetica;
  font-weight:normal;
  text-transform: uppercase;
  h1 {
    color:#fff;
  }
  h2 {
    color: #d2ff00;
  }
}

#content, #sitefooter, #headerMatch, #splash, .ReactModalPortal {
  .font-chakra {
    font-family: 'Chakra Petch',Helvetica;
    font-weight:normal;
  }
  .font-chakra.bold, .font-chakra .bold {
    font-weight:bold;
  }
  .font-anton {
    font-family:'Anton',Helvetica;
    font-weight:normal;
    text-transform: uppercase;
  }
  .font-anton.bold, .font-anton .bold {
    font-weight:bold;
  }

  #submenu {
    background-color:#fff;
    color:#000;
    position:relative;
    margin-top:30px;
    padding:15px 15px;
    display:flex;
    width:100%;
  }
  #submenu .option {
    flex-basis: auto;
    padding: 8px 10px 8px 10px;
    border-radius: 5px;
  }
  #submenu .option.on {
    background-color:#d2ff00;
    color:#000;
  }

  .MatchCard {
    margin: 5px 0px;
  }
  .MatchCard .header {
    width:100%;
    background-color: rgba(70,32,115,255);
    display:flex;
    align-items: center;
  }
  .MatchCard .footer {
    width:100%;
    background-color: rgba(70,32,115,255);
    display:flex;
    align-items: center;
  }
  .MatchCard .footer .action {
    display:flex;
    font-family: 'Chakra Petch',Helvetica;
    font-weight:normal;
    font-size: 12px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  .MatchCard .footer .prompt {
    background-color: #d2ff00;
    padding:14px 10px;
    text-align:right;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MatchCard .header .title {
    color:#fff;
    display: inline-block;
    font-family: 'Chakra Petch',Helvetica;
    padding:8px 10px;
    font-size:20px;
    max-width:65%;
  }
  .MatchCard .header .schedule {
    margin-left:auto;
    text-align: right;
    font-family: 'Chakra Petch',Helvetica;
    font-weight:normal;
    font-size: 12px;
    align-items: center;
  }
  .MatchCard .header .schedule.live {
    color:#f73261;
    display: flex;
  }
  .live-indicator-solid {
    width: 14px;
    height: 14px;
    position: relative;
    top: 0;
    left: 5px;
    border-radius: 7px;
    border: 1px solid #f7326199;
    background-color: #f7326199;
    margin-left: 10px;
    margin-top: 5px;
    animation: fadeAnimation 1.5s infinite; /* Apply the animation */
  }
  .live-indicator-solid:nth-child(1) {
    animation-delay: 0s;
  }
  .live-indicator-solid:nth-child(2) {
    animation-delay: 0.5s;
  }
  .live-indicator-solid:nth-child(3) {
    animation-delay: 1s;
  }

  .live-indicator {
    width: 14px;
    height: 14px;
    position: relative;
    top: 0;
    left: 5px;
    border-radius: 7px;
    border: 1px solid #f7326199;
  }
  .live-indicator .inner {
      width: 8px;
      height: 8px;
      position: relative;
      top: 2px;
      left: 2px;
      background-color: #f73261;
      border-radius: 4.2px;
  }


  .submit-indicator-solid {
    width: 6px;
    height: 6px;
    background-color: #1b062f99;
    animation: fadeAnimation 1.5s infinite; /* Apply the animation */
  }
  .submit-indicator-solid:nth-child(1) {
    animation-delay: 0s;
  }
  .submit-indicator-solid:nth-child(2) {
    animation-delay: 0.5s;
  }
  .submit-indicator-solid:nth-child(3) {
    animation-delay: 1s;
  }

  .submit-indicator {
    width: 6px;
    height: 6px;
    position: relative;
    top: 0;
    left: 5px;
    border: 1px solid #1b062f99;
  }
  .submit-indicator .inner {
      width: 2px;
      height: 2px;
      position: relative;
      top: 2px;
      left: 2px;
      background-color: #1b062f99;
  }

  .MatchCard .body {
    display:flex;
    min-width:100%;
  }
  .MatchCard .body .homeTeam, .MatchCard .body .awayTeam {
    width:calc(50% - 1px);
    background-color:#fff;
    color:#1b062f;
    padding: 0 10px;
    font-family: 'Chakra Petch',Helvetica;
    font-weight:normal;
    font-size: 12px;
    
  }
  .MatchCard .body .homeTeam {
    margin-right:1px;
  }
  .MatchCard .body .awayTeam {
    margin-left:1px;
  }
  .MatchCard .body .homeTeam .top, .MatchCard .body .awayTeam .top {
    display:flex;
    height: 36px;
  }
  .MatchCard .body .homeTeam .top .scoreline, .MatchCard .body .awayTeam .top .scoreline {
    margin-left:auto;
    text-align:right;
    font-weight:bold;
  }
}
#sitefooter {
  color: #fff;
  font-family:'Anton',Helvetica;
  font-weight:normal;
  transform: translateY(-50px);
  text-transform: uppercase;
}

.yellowBox {
  background-color:#d2ff00;
  font-family:'Anton',Helvetica;
  font-weight: normal;
  color:#1b062f;
  font-size: 14px;
  display:inline-block;
  padding:5px;
  text-transform: uppercase;
}
.subTitle {
  padding-top:4px;
  margin-left:10px;
  text-transform: uppercase;
}
.dateDisplay {
  font-family: 'Chakra Petch',Helvetica;
  font-weight:normal;

}
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #1b062f;
  border-radius: 0px;
  overflow: hidden;
  right:0px;
  background-color:#fff;
}
.select-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  width: 1px;
  background-color: #1b062f;
}

select.dropdown {
  font-family: 'Chakra Petch', Helvetica;
  font-weight: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px;
  padding-right: 40px;
  border: none;
  background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none"><path d="M1.22266 5.66016L6.72266 11.1602L12.2227 5.66016" stroke="%2318062F" /></svg>') no-repeat;
  background-position: right 8px top 8px !important;
  background-size: 13px 12px;
  cursor: pointer;
  width: 100%;
}
select.dropdown#country {
  background-position: right 8px top 12px !important;
}
.select-wrapper {
  width: 90%;
}
.enteremail {
  background: white;
  border:none;
  font-family: 'Chakra Petch',Helvetica;
  font-weight:normal;
  font-size: 14px;
  color:#1B062F;
  height:36px;
  width:100%;
}
.enteremail::placeholder {
  text-align: left;
  padding-left: 5px;
}

.enteremail:focus {
  outline:none;
}
.enteremail2 {
  background: transparent;
  border:none;
  font-family: 'Chakra Petch',Helvetica;
  font-weight:normal;
  font-size: 14px;
  color:#37224d;
  height:36px;
  width:100%;
}
.enteremail2::placeholder {
  text-align: left;
  padding-left: 5px;
}

.enteremail2:focus {
  outline:none;
}

.standingInput {
  font-family: 'Chakra Petch',Helvetica;
  font-weight:normal;
  font-size: 14px;
  background: transparent;
  border:none;
  width:100%;
  margin-left:10px;
}
.standingInput:focus {
  outline:none;
}
#breakout {
    box-sizing: border-box;
    position: absolute;
    width: calc(100% + 30px);
    left: -15px;
    height:358px;
    top:170px;
}
.diagonal-gradient {
  background: linear-gradient(45deg, #321F45, #4b2c70);
}

.blogpost {
  font-family: 'Chakra Petch',Helvetica;
  font-weight:normal;
  color:#1b062f;
  padding-bottom: 100px;
  font-size: 14px;
  line-height: 18px;
  p {
    padding-bottom:10px;
  }
  hr {
    position: relative;
    display: block;
    height: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    opacity: 1;
    border: none;
    background-color: transparent;
    background-image: radial-gradient(currentColor 1px, transparent 1px);
    background-repeat: repeat;
    background-position: 1px 1px;
    background-size: 5px 5px;
  }
  a {
    color: #1b062f;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: underline;
  }
  strong {
    font-weight:700;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0;
  }
  img {
    padding-bottom: 20px;
    max-width: 100%;
    height:auto;
  }
}

/* Add this CSS to your stylesheet */
.modal {
  font-family:'Anton',Helvetica;
  font-weight:normal;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  width: 300px;
  text-align: center;
  z-index: 1000;
  text-transform: uppercase;
  border:3px solid #401A6E;
}
.modal h2 {
  background-color:#d2ff00;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  text-align: center;
  padding-top:10px;
  padding-bottom:10px;
  text-transform:uppercase;

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.share-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.copy-link button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.share-button {
  all:unset;
  cursor: pointer !important;
  .btn {
    text-transform: uppercase;
    font-weight:normal;
    font-family:'Anton',Helvetica;
    font-size: 18px;
    padding: 14px 20px;
    background-color: #d2ff00;
    color: #1b062f;
    border: 1px solid #1b062f;
  }
}
.customGoogleBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;


}
.ReactModalPortal {
  button {
    all: unset;
    color:#fff;
    cursor:pointer;
  }
}

.MatchPage .main-container {
  @screen md {
    padding: 20px;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr;
    grid-template-areas: 
      "names names video video"
      "score score video video"; 
  }
}

.MatchPage .main-container .names-container {
  grid-area: names;
}

.MatchPage .main-container .video-container {
  grid-area: video;
}

.MatchPage .main-container .scorebox-container {
  grid-area: score;
}



@media (max-width: 400px) {
  #Modalspinner {
    width: calc(100vw - 20px) !important;
  }
}

.ReactModal__Content {
  overflow:hidden !important;
}


.diagonal-gradient {
  width: 100%;
  height: 100%;
  background:none;
}

@media (max-width: 640px) {
  .diagonal-gradient {
    background: linear-gradient(to top right, #3e196b, #1d0833);
  }
}

